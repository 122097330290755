import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { BigNumberish } from "@ethersproject/bignumber";
import BigNumber from "bignumber.js";

export interface IBid {
  id: string;
  bidder: {
    id: string;
  };
  amount: BigNumber;
  blockNumber: number;
  blockTimestamp: number;
  txIndex?: number;
  teddy: {
    id: number;
    startTime?: BigNumberish;
    endTime?: BigNumberish;
    settled?: boolean;
  };
}

export interface Teddy {
  id: string;
  seed: {
    id: string;
    background: number;
    bodyAccessory: number;
    headAccessory: number;
    body: number;
    face: number;
  };
}

interface ProposalVote {
  supportDetailed: 0 | 1 | 2;
  voter: {
    id: string;
  };
}

export interface ProposalVotes {
  votes: ProposalVote[];
}

export interface Delegate {
  id: string;
  teddyRepresented: {
    id: string;
  }[];
}

export interface Delegates {
  delegates: Delegate[];
}

export const seedsQuery = (first = 1_000) => gql`
  {
    seeds(first: ${first}) {
      id
      background
      body
      headAccessory
      bodyAccessory
      face
    }
  }
`;

export const auctionQuery = (auctionId: number) => gql`
  {
    auction(id: ${auctionId}) {
      id
      amount
      settled
      bidder {
        id
      }
      startTime
      endTime
      teddy {
      id
      seed {
        id
        background
        body
        bodyAccessory
        headAccessory
        face
      }
      owner {
        id
      }
      }
      bids {
      id
      blockNumber
      txIndex
      amount
      }
    }
  }
`;

export const bidsByAuctionQuery = (auctionId: string) => gql`
  {
    bids(
      where:{auction: "${auctionId}" }, 
      orderBy: amount
      orderDirection: desc
    ) {
      id
      value: amount
      blockNumber
      blockTimestamp
      txIndex
      donorName
      charityName
      bidder {
        id
      }
      teddy {
        id
      }
    }
  }
`;

export const teddyQuery = (id: string) => gql`
  {
    teddy(id:"${id}") {
      id
      owner {
        id
      }
      seed {
        id
        background
        body
        bodyAccessory
        headAccessory
        face
      }
    }
  }
 `;

export const teddyIndex = () => gql`
  {
    teddy {
      id
      owner {
        id
      }
    }
  }
`;

export const latestAuctionsQuery = () => gql`
  {
    auctions(orderBy: startTime, orderDirection: desc, first: 1000) {
      id
      amount
      settled
      bidder {
        id
      }
      startTime
      endTime
      teddy {
        id
        owner {
          id
        }
      }
      bids {
        id
        amount
        blockNumber
        blockTimestamp
        txIndex
        charityName
        donorName
        bidder {
          id
        }
      }
    }
  }
`;

export const latestBidsQuery = (first: number = 10) => gql`
  {
    bids(
      first: "${first}",
      orderBy:blockTimestamp,
      orderDirection: desc
    ) {
      id
      bidder {
        id
      }
      amount
      blockTimestamp
      txIndex
      blockNumber
      auction {
        id
        startTime
        endTime
        settled
      }
    }
  }  
`;

export const charitiesQuery = () => gql`
  {
    charities(
      orderBy: totalDonationsReceived
      orderDirection: desc
      where: { totalDonationsReceived_gt: 0 }
    ) {
      name
      totalDonationsReceived
      id
    }
  }
`;

export const accountsQueryByTotalDonations = () => gql`
  {
    accounts(
      orderBy: totalDonations
      orderDirection: desc
      where: { totalDonations_gt: 0 }
    ) {
      id
      totalDonations
    }
  }
`;

export const accountQuery = (account: string) => gql`
  {
    account(id: "${account}") {
      totalDonations
      id
      teddies {
        id
        seed {
          id
          background
          bodyAccessory
          headAccessory
          body
          face
        }
      }
    }
  }
`;

export const queryTeddyWinningBid = (teddyId: string, account: string) => gql`
  {
    bids(
      where: {
        teddy: "${teddyId}"
        bidder_contains_nocase: "${account}"
      }
      orderBy: amount
      orderDirection: desc
      first: 1
    ) {
      id
      txIndex
      amount
      teddy {
        id
      }
      bidder {
        id
      }
    }
  }
`;

export const auctionsByAccountQuery = (account: string) => gql`
  {
    auctions(where: { bidder: "${account}", settled: true }) {
      id
      amount
      teddy {
        id
      }
      endTime
      startTime
    }
  }
`;

export const delegateTeddyAtBlockQuery = (
  delegates: string[],
  block: number
) => gql`
{
  delegates(where: { id_in: ${JSON.stringify(
    delegates
  )} }, block: { number: ${block} }) {
    id
    teddyRepresented {
      id
    }
  }
}
`;

export const currentlyDelegatedTeddy = (delegate: string) => gql`
{
  delegates(where: { id: "${delegate}"} ) {
    id
    teddyRepresented {
      id
    }
  }
}
`;

export const clientFactory = (uri: string) =>
  new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
