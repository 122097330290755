import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import { DonationReceipt } from "../DonationReceipt";

interface DonationReceiptModalProps {
  onClose: () => void;
  isOpen: boolean;
  imagePng: string | null | undefined;
  teddyId: string | undefined;
  txLink: string | undefined;
  txHash: string | undefined;
}
export const DonationReceiptModal = ({
  onClose,
  isOpen,
  imagePng,
  teddyId,
  txLink,
  txHash
}: DonationReceiptModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="2xl">
        <ModalCloseButton />
        <ModalBody pt={12} pb={12}>
          <DonationReceipt
            imagePng={imagePng}
            teddyId={teddyId}
            onDownloadReceipt={() => console.log("")}
            txLink={txLink}
            txHash={txHash}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
