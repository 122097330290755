import { Box, Heading, HStack, Text, Button, Link } from "@chakra-ui/react";
import React, { ReactInstance } from "react";
import {
  PDFDownloadLink,
  Image,
  Link as PDFlink,
  Document,
  Page,
  StyleSheet,
  Text as PDFText,
  View
} from "@react-pdf/renderer";

import Teddy from "../Teddy";
import { colors } from "../../theme";

interface DonationReceiptProps {
  imagePng: string | null | undefined;
  teddyId: string | undefined;
  onDownloadReceipt: () => void;
  txLink: string | undefined;
  txHash: string | undefined;
}

interface DonationReceiptMessageProps {
  imagePng: string | null | undefined;
  teddyId: string | undefined;
  txLink: string | undefined;
  txHash: string | undefined;
}

export const DonationReceiptMessage = ({
  imagePng,
  teddyId,
  txHash,
  txLink
}: DonationReceiptMessageProps) => {
  return (
    <div>
      {/** @ts-ignore */}
      <Box p={4} backgroundColor="white">
        <Box
          backgroundColor="brand.500"
          height="64px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Heading
            width="100%"
            as="h3"
            fontSize="3xl"
            textAlign="center"
            color="white"
          >
            Thank You!
          </Heading>
        </Box>
        <HStack alignItems="flex-start" py={8}>
          <Box minWidth="250px">
            {imagePng && (
              <Teddy
                imgPath={imagePng}
                alt="teddy"
                // className={classes.teddyImg}
                // wrapperClassName={classes.teddyWrapper}
              />
            )}
          </Box>
          <Box>
            <Text fontSize="2xl" fontWeight="bold">
              Donation Receipt for your Teddy DAO NFT Purchase
            </Text>
            <Box fontSize="sm">
              <Text>17, Chilterns Business Centre</Text>
              <Text>63-65 Woodside Road</Text>
              <Text>Amersham</Text>
              <Text>United Kingdom</Text>
              <Text>HP6 6AA</Text>
              <Text>Tax ID #: 1137409</Text>
              <Text>DATE: Tue Aug 09 2022 10:08 UTC+00:00</Text>
            </Box>
          </Box>
        </HStack>
        <Box py={8}>
          <Text fontSize="2xl" fontWeight="bold">
            Your Tax Receipt
          </Text>
          <Text>
            <Text as="span" fontWeight="bold" textTransform="uppercase" mr={3}>
              Receipt ID:
            </Text>
            <Text as="span">{teddyId}</Text>
          </Text>
          <Text>
            <Text fontWeight="bold" textTransform="uppercase" mr={3}>
              Transaction ID:
            </Text>
            <Link href={txLink} rel="noopener noreferrer nofollow" target="_blank" textDecoration="underline">{txHash}</Link>
          </Text>
          <Text>
            <Text as="span" fontWeight="bold" textTransform="uppercase" mr={3}>
              Item:
            </Text>
            <Text as="span">Online cryptocurrency Donation</Text>
          </Text>
          <Text>
            <Text as="span" fontWeight="bold" textTransform="uppercase" mr={3}>
              Quantity:
            </Text>
            <Text as="span">1</Text>
          </Text>
          <Text py={4}>
            <Text as="span" fontWeight="bold" textTransform="uppercase" mr={3}>
              FOR YOUR TAX PURPOSES:
            </Text>
            <Text as="span">
              Your donation is tax deductible to the extent allowed by law.
              Please save this letter for your tax records as confirmation of
              your donation. If you have any questions, please email
              support@teddydao.org.
            </Text>
          </Text>
        </Box>
      </Box>
    </div>
  );
};

const styles = StyleSheet.create({
  page: {
    paddingTop: `32px`,
    paddingBottom: `32px`,
  },
  thankYou: {
    backgroundColor: colors.brand[500],
    height: `64px`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    color: `white`,
    fontWeight: `bold`,
    marginLeft: `32px`,
    marginRight: `32px`,
    marginBottom: `32px`,
  },
  imageSection: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `center`,
  },
  image: {
    width: `150px`,
  },
  heading: {
    textAlign: `center`,
    fontWeight: `bold`,
    overflowWrap: `break-word`,
    marginBottom: `16px`,
  },
  itemHeading: {
    fontWeight: `bold`,
    marginRight: `8px`,
  },
  itemGroup: {
    display: `flex`,
    flexDirection: `row`,
    marginBottom: `8px`,
  }
});

export const DonationReceipt = React.forwardRef<ReactInstance, DonationReceiptProps>(
  (props, ref) => (
    <>
      <DonationReceiptMessage
        imagePng={props.imagePng}
        teddyId={props.teddyId}
        txHash={props.txHash}
        txLink={props.txLink}
      />
      <Box px={4}>
        <Button
          as={PDFDownloadLink}
          variant="solid"
          colorScheme="teal"
          _hover={{ color: `white` }}
          document={
            <Document>
              <Page style={styles.page}>
                <View style={styles.thankYou}>
                  <PDFText
                    style={{
                      ...styles.heading,
                      marginBottom: 0,
                    }}
                  >
                    Thank You!
                  </PDFText>
                </View>
                <PDFText style={styles.heading}>
                  Donation Receipt for your Teddy DAO NFT Purchase
                </PDFText>
                <View style={styles.imageSection}>
                  <Image style={styles.image} src={props.imagePng as any} />
                  <View style={{ marginLeft: `32px`, fontSize: `12px` }}>
                    <PDFText>17, Chilterns Business Centre</PDFText>
                    <PDFText>63-65 Woodside Road</PDFText>
                    <PDFText>Amersham</PDFText>
                    <PDFText>United Kingdom</PDFText>
                    <PDFText>HP6 6AA</PDFText>
                    <PDFText>Tax ID #: 1137409</PDFText>
                    <PDFText>DATE: Tue Aug 09 2022 10:08 UTC+00:00</PDFText>
                  </View>
                </View>
                <View style={{ marginLeft: `32px`, marginRight: `32px` }}>
                  <PDFText style={{ fontWeight: `bold`, fontSize: `18px` }}>
                    Your Tax Receipt
                  </PDFText>
                  <View style={styles.itemGroup}>
                    <PDFText style={styles.itemHeading}>Receipt ID:</PDFText>
                    <PDFText>{props.teddyId}</PDFText>
                  </View>
                  <View
                    style={{
                      ...styles.itemGroup,
                      flexDirection: `column`,
                    }}
                  >
                    <PDFText style={styles.itemHeading}>
                      Transaction Link:
                    </PDFText>
                    <PDFText>
                      <PDFlink src={props.txLink!}>{props.txLink}</PDFlink>
                    </PDFText>
                  </View>
                  <View
                    style={{
                      ...styles.itemGroup,
                    }}
                  >
                    <PDFText style={styles.itemHeading}>Item:</PDFText>
                    <PDFText>Online cryptocurrency Donation</PDFText>
                  </View>
                  <View style={styles.itemGroup}>
                    <PDFText>Quantity:</PDFText>
                    <PDFText>1</PDFText>
                  </View>
                  <View>
                    <PDFText style={styles.itemHeading}>
                      FOR YOUR TAX PURPOSES
                    </PDFText>
                    <PDFText>
                      Your donation is tax deductible to the extent allowed by
                      law. Please save this letter for your tax records as
                      confirmation of your donation. If you have any questions,
                      please email support@teddydao.org.
                    </PDFText>
                  </View>
                </View>
              </Page>
            </Document>
          }
        >
          Download receipt
        </Button>
      </Box>
    </>
  )
);
